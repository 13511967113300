import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import 'react-awesome-slider/dist/styles.css';

import './index.css';
import App from './components/App';
import DetailsPage from './components/DetailsPage';
import WeddingPhotosPage from './components/WeddingPhotosPage';

import * as serviceWorker from './serviceWorker';

ReactDOM.render(<Router>
  <Switch>
  <Route path="/wedding">
      <WeddingPhotosPage />
    </Route>
    <Route path="/details">
      <DetailsPage />
    </Route>
    <Route path="/">
      <App />
    </Route>
  </Switch>
</Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
