// @flow
import React from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ImageGallery from "react-image-gallery";

import "react-image-gallery/styles/css/image-gallery.css";

import photoData from "./photoData";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#111111",
    padding: "5em 10em",
    height: "90vmin",
  },
});

const WeddingPhotosPage = ({ intl }): Props => {
  const classes = useStyles();

  return (
    <Grid
      className={classes.root}
      container
      justify="center"
      alignItems="center"
    >
      <Grid item xs="12">
        <ImageGallery
          items={photoData.map((photo) => ({
            original: photo.src,
            thumbnail: photo.thumbnail,
          }))}
          lazyLoad
        />
      </Grid>
    </Grid>
  );
};

export default WeddingPhotosPage;
