import React from "react";
import Grid from "@material-ui/core/Grid";
import { Link , useHistory} from "react-router-dom";

import PhotoViewer from "../PhotoViewer";
import { ReactComponent as Logo } from "./swirly.svg";
import "./App.css";

function App() {
  const history = useHistory();
  console.log(history);

  if (history.location.pathname === '/wedding') {
    history.push('/wedding');
  }

  return (
    <div>
      <Logo width="150px" style={{ position: "relative", top: 0 }} />
      <Logo
        width="150px"
        style={{
          position: "relative",
          top: 0,
          float: "right",
          transform: "rotate(90deg)",
        }}
      />
      <Grid
        className="App"
        direction="column"
        justify="center"
        alignItems="center"
        spacing={2}
        container
      >
        <Grid item></Grid>
        <Grid item>
          <img
            alt="October 30 2020, Ben & Mary"
            width="70%"
            src="/img/heading.png"
          />
        </Grid>
        <Grid item style={{ width: "70%" }}>
          <PhotoViewer />
        </Grid>
        <Grid item>
          <button className="link">#benandmarys</button>
        </Grid>
        <Grid item>
          <Link to="/wedding">
            <img alt="details" src="/img/photos_button.png" width="300px" />
          </Link>
        </Grid>
      </Grid>
      <Logo
        width="150px"
        style={{
          position: "relative",
          bottom: 0,
          left: 0,
          transform: "rotate(270deg)",
        }}
      />
      <Logo
        width="150px"
        style={{
          position: "relative",
          bottom: 0,
          float: "right",
          transform: "rotate(180deg)",
        }}
      />
    </div>
  );
}

export default App;
