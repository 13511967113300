import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";

import tileData from "./data";
import './style.css';

const AutoplaySlider = withAutoplay(AwesomeSlider);

const useStyles = makeStyles(theme => ({
  root: {},
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)"
  },
  img: {
    borderRadius: 5
  },
  title: {
    color: theme.palette.primary.light
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
  },
  paper: {
    padding: 5
  }
}));

const PhotoViewer = (props: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AutoplaySlider
        play
        bullets={false}
        cancelOnInteraction={false}
        interval={4000}
        media={tileData}
        className="aws"
      />
    </div>
  );
};

export default PhotoViewer;
