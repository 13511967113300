import React from "react";
import Grid from "@material-ui/core/Grid";

import { ReactComponent as Logo } from "./swirly.svg";
import "./DetailsPage.css";

const DetailsPage = () => {
  return (
    <div>
      <Logo width="150px" style={{ position: "relative", top: 0 }} />
      <Logo
        width="150px"
        style={{
          position: "relative",
          top: 0,
          float: "right",
          transform: "rotate(90deg)"
        }}
      />
      <Grid
        className="details"
        direction="column"
        justify="center"
        alignItems="center"
        spacing={2}
        container
      >
        <Grid item className="details-item">
          <h2>R.S.V.P.</h2>
          <p>Please RSVP by July 1st</p>
          <p>
            Register by emailing:{" "}
            <a href="mailto:melnyk.a.mary@gmail.com">melnyk.a.mary@gmail.com</a>
          </p>
        </Grid>
        {/* <Grid item className="details-item">
          <h2>Hotels</h2>
          <p>TBD, but we will try and get a deal somewheres</p>
        </Grid> */}
        <Grid item className="details-item">
          <h2>Schedule</h2>
        </Grid>
        <Grid item container>
          <Grid
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
            className="details-schedule"
            spacing={4}
          >
            <Grid item>
              <h3>Ceremony</h3>
            </Grid>
            <Grid item>
              <p>October 30, 2020</p>
              <p>Please arive at 12pm, ceremony begins at 1pm</p>
              <p>The Ranch, Canmore AB</p>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={4}
            className="details-schedule details-item"
          >
            <Grid item>
              <h3>Cocktail Hour</h3>
            </Grid>
            <Grid item>
              <p>October 30, 2020 at 5pm</p>
              <p>Cornerstone theatre, Canmore AB</p>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={4}
            className="details-schedule details-item"
          >
            <Grid item>
              <h3>Reception</h3>
            </Grid>
            <Grid item>
              <p>October 30, 2020 at 6pm</p>
              <p>Cornerstone theatre, Canmore AB</p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Logo
        width="150px"
        style={{
          position: "relative",
          bottom: 0,
          left: 0,
          transform: "rotate(270deg)"
        }}
      />
      <Logo
        width="150px"
        style={{
          position: "relative",
          bottom: 0,
          float: "right",
          transform: "rotate(180deg)"
        }}
      />
    </div>
  );
};

export default DetailsPage;
